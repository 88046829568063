import React, {Component} from "react"
import styles from "./Main.module.css"
import {observer} from "mobx-react"
import {observable} from "mobx"
import {Model} from "../../Model"

interface Props {
    model: Model
}

@observer
export class Main extends Component<Props, {}> {
    @observable
    private index = 0

    private iframe: HTMLIFrameElement
    public render() {
        const {model} = this.props
        const token = this.props.model.gate.getToken()

        const visitor = model.visitor
        let status

        if (!visitor) {
            return null
        }

        if (visitor) {
            status = `ID=${visitor.id} ${visitor.first_name} ${visitor.last_name} ${visitor.email} ${visitor.role}`
        }

        return (
            <div>
                {/* <div>{visitor && status}</div> */}
                <iframe
                    title="PDF Preview"
                    src={`${process.env.REACT_APP_WEB_DOMAIN}?token=${token}&user_id=${visitor.id}`}
                    className={styles.iframe}
                    allowFullScreen
                    ref={(f) => {
                        this.iframe = f as HTMLIFrameElement
                    }}
                />
            </div>
        )
    }
}

import * as React from "react"
import * as ReactDOM from "react-dom"
import "./index.css"
import {Model} from "./Model"
import {GateListener} from "./lib/gate/GateListener"
import {createBrowserHistory} from "history"
import {Gate} from "./lib/gate/Gate"
import {Router as ReactRouter} from "react-router"
import {AuthLayer} from "./components/AuthLayer"
import {Bridge} from "./Bridge"
import {getParameterByName} from "./lib/functions/getParameterByName"

const lead_source = getParameterByName("etrk")
if (lead_source && lead_source !== "") {
    localStorage.setItem("lead_source", lead_source)
}

document.domain = document.domain.replace("platform.", "")

const gate = new Gate()
new GateListener(gate)
const history = createBrowserHistory()
const model = new Model(gate, history)

async function main() {
    await model.auth.refreshToken()
    await model.loadMe()
}
main()

// @ts-ignore
window.model = model

// @ts-ignore
window.bridge = new Bridge(model)

ReactDOM.render(
    <ReactRouter history={history}>
        <AuthLayer gate={gate} model={model} auth={model.auth} />
    </ReactRouter>,
    document.getElementById("root")
)
